/* eslint-disable no-confusing-arrow */
import storage from '@utils/storage.js'

const name = 'cart-dashboard'
export default {
  namespaced: true,
  state: {
    items: [],
    discount: [],
  },
  getters: {
    items: state => state.items,
    discount: state => state.discount,
    total: state => state.items.length > 0 ? state.items.map(i => i.quantity).reduce((a, b) => a + b, 0) : 0,
    price: state => state.items.length > 0 ? state.items.filter(i => !i.notToUse).map(i => i.price * i.quantity).reduce((a, b) => Number(a) + Number(b)) - (state.discount?.length > 0 ? state?.discount?.map(d => d?.amount).reduce((a, b) => a + b, 0) : 0) : 0,
  },
  mutations: {
    add(state, data) {
      const old = state.items.findIndex(item => item.variationId === data.variationId)
      if (old >= 0) {
        state.items[old] = { ...data, quantity: state.items[old].quantity + data.quantity }
        state.items = [...state.items]
      } else {
        state.items.push(data)
      }
      storage.update({ items: state.items, discount: state.discount }, name)
    },
    remove(state, id) {
      state.items = state.items.filter(item => item.variationId !== id && (item.linkWith !== id))
      storage.update({ items: state.items, discount: state.discount }, name)
    },
    reset(state) {
      state.items = []
      state.discount = []
      storage.remove(name)
    },
    initialize(state) {
      if (storage.get(name)) {
        state.items = storage.get(name).items
      } else {
        storage.update({ items: state.items, discount: state.discount }, name)
      }
    },
    addDiscount(state, data) {
      state.discount.push(data)
      storage.update({ items: state.items, discount: state.discount }, name)
    },
    resetDiscount(state) {
      state.discount = []
      storage.update({ items: state.items, discount: state.discount }, name)
    },
  },
  actions: {
    add({ commit }, data) {
      const item = {
        title: data.title || data.name,
        slug: data.slug,
        productId: data.id,
        sku: data.sku,
        variationId: data.variationId,
        image: data.images[0],
        price: Number(data.price),
        taxe: data.total_tax,
        lattes: data.lattes || data.Lattes,
        'tete-de-lit': data['tete-de-lit'] || data['Tête de lit'],
        size: data.taille,
        color: data.couleur,
        quantity: data.quantity,
        type: data.type,
        notToUse: data.notToUse,
        priceCadeau: data.priceCadeau,
        categories: data.categories?.map(c => c.slug),
      }
      if (data.meta_data?.length) {
        item.meta_data = [
          ...data.meta_data,
          {
            key: '_isTakeAway',
            value: data.isTakeAway || false,
          }]
        item.total = data.total
      } else {
        item.meta_data = [{
          key: '_isTakeAway',
          value: data.isTakeAway || false,
        }]
      }
      if (data.repriseAncienMatelas || data.repriseAncienSommier || data.repriseAncienCanape) {
        // item.price += 29
        item.meta_data.push({
          key: '_reprise_ancien',
          value: 0,
        })
      }
      if (data.montageSommier) {
        item.price += 69
        item.meta_data.push({
          key: '_montage',
          value: 69,
        })
      }
      if (data.montageLitCoffre) {
        item.price += 99
        item.meta_data.push({
          key: '_montage_lit_coffre',
          value: 99,
        })
      }
      if (data.woosb?.length) {
        item.woosb = data.woosb
      }
      if (data.isOffer !== null && data.isOffer !== undefined) {
        item.isOffer = data.isOffer
      }
      if (data.linkWith) {
        item.linkWith = data.linkWith
        item.variationId = `${item.variationId}-offer`
      }
      commit('add', item)
    },
    remove({ commit }, id) {
      commit('remove', id)
    },
    reset({ commit }) {
      commit('reset')
    },
    initialize({ commit }) {
      commit('initialize')
    },
    addDiscount({ commit }, data) {
      commit('addDiscount', data)
    },
    resetDiscount({ commit }) {
      commit('resetDiscount')
    },
  },
}
